import React, { forwardRef, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { MoonLoader } from "react-spinners";
import { MapPinIcon } from "@heroicons/react/24/solid";
import { Typography } from "../../components";
import { Button } from "../../components/Button";
import {
  useCreatePinMutation,
  useGetSchoolsQuery,
  useLazyGetSchoolProfileQuery,
  useSendAuthenticationCodeMutation,
  useVerifyAuthCodeMutation,
} from "../../app/api";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsOpenModal,
  setSchoolId,
  setToggleModal,
} from "../../app/slice";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useForm, useFormContext } from "react-hook-form";
import NigeriaFlag from "../../assets/nigeriaFlag.svg";
import cn from "classnames";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { appendCountryCode } from "../../helpers";
import { useCountdown } from "../../hooks/useCountdown";
import { XMarkIcon } from "@heroicons/react/20/solid";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { phoneWithoutCountryCode } from "../../helpers";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-input-2/lib/style.css";
const signSchema = yup.object().shape({
  email: yup.string().email().required("Email is required."),
  phoneNumber: yup.string().required(" Phone number is required"),
});

const CustomArrow = ({ className, style, onClick }) => (
  <div className={className} style={{ ...style }} onClick={onClick} />
);

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <CustomArrow
      className={className}
      style={{
        ...style,
        color: "#fff",
        backgroundColor: "#671E78",
        borderRadius: "50%",
      }}
      onClick={onClick}
      color="#fff"
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <CustomArrow
      className={className}
      style={{
        ...style,
        left: "-30px",
        borderRadius: "50%",
        color: "#fff",
        backgroundColor: "#671E78",
      }}
      onClick={onClick}
    />
  );
};

const Input = forwardRef(
  (
    { error = "", startAdornment, label = "", className = "", ...rest },
    ref
  ) => {
    return (
      <>
        <label className="text-[#808285] mb-2">{label}</label>
        <div className="relative mt-3 mb-3">
          <div className="absolute">{startAdornment}</div>
          <input
            {...rest}
            ref={ref}
            className={cn(
              "border-b-2 border-black pb-2 w-full mb-1 ring-0 outline-none",
              className,
              {
                "pl-20": typeof startAdornment !== "undefined",
              }
            )}
          />
          {error.length !== 0 && (
            <Typography as="p" className="text-red-800 text-sm">
              {error}
            </Typography>
          )}
        </div>
      </>
    );
  }
);

const Modal = ({ children, ref, isOpen }) => {
  return (
    <div
      ref={ref}
      className={`items-center justify-center fixed left-0 bottom-0 w-full h-full bg-[rgba(0,0,0,0.5)] z-[1000] select-none  ${
        isOpen ? "flex" : "hidden"
      }`}
    >
      {children}
    </div>
  );
};

const SliderComponent = ({ props, schoolIndex }) => {
  const divRef = useRef(null);

  const { schools } = useGetSchoolsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      schools: data?.data ?? [],
    }),
  });
  // console.log({schools})
  const [onSchoolProfile] = useLazyGetSchoolProfileQuery();
  const [onSendAuthCode, { isLoading }] = useSendAuthenticationCodeMutation();
  const [onVerifyPin, { isLoading: isVerifying }] = useVerifyAuthCodeMutation();
  const [onCreatePin, { isLoading: isCreatingPin }] = useCreatePinMutation();

  const [isModal, setIsModal] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [selectedSchool, setSelectedSchools] = useState(schools?.[0]);
  const [step, setStep] = useState(1);

  const dispatch = useDispatch();
  const isOpenModal = useSelector(selectIsOpenModal);
  useClickOutside(divRef, () => setIsModal(false));

  const { Timer } = useCountdown({
    initialHour: 0,
    initialMinute: 2,
    initialSeconds: 12,
    isStart: step === 2,
    onComplete: () => setShowResend(true),
  });

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(signSchema) });

  const [OTP, setOTP] = useState("");
  const [pins, setPins] = useState({ pin: "", confirmPin: "" });
  const [browserToken, setBrowserToken] = useState("");

  const handleSchoolSelection = async (school) => {
    dispatch(setSchoolId(school?.schoolid));
    try {
      setSelectedSchools(school);
      await onSchoolProfile({
        id: school?.schoolid,
      }).unwrap();
      // save the result payload to redux store.
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data) => {
    // console.log(data);
    try {
      const result = await onSendAuthCode({
        phoneNumber: phoneWithoutCountryCode(data.phoneNumber).phoneNumber,
        email: data.email,
        countryCode: "234",
      }).unwrap();
      if (!result.status) {
        toast.error(result?.message);
        return;
      }
      result.status && setStep(2);
      toast.success(result?.message ?? "One time password sent!");
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
  };

  /**
   * It takes the phone number and the OTP from the form and sends it to the backend to verify the OTP
   */
  const onAuthSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await onVerifyPin({
        phoneNumber: phoneWithoutCountryCode(getValues("phoneNumber"))
          ?.phoneNumber,
        code: OTP,
      }).unwrap();

      if (!result.status) {
        toast.error(result?.Message);
        return;
      }
      result.status && setBrowserToken(result?.data?.browserToken);
      result.status && setStep(3);
      toast.success(result?.message ?? "One time password sent!");
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
  };

  /**
   * It takes the phone number and pin from the form, and sends it to the backend to create a pin
   */
  const handleCreatePin = async (e) => {
    e.preventDefault();
    try {
      if (pins.pin !== pins.confirmPin) {
        toast.error("Pins doesn't match");
        return;
      }

      const result = await onCreatePin({
        phoneNumber: phoneWithoutCountryCode(getValues("phoneNumber"))
          ?.phoneNumber,
        pin: pins.pin,
        browserToken,
        accountType: 2,
      }).unwrap();
      // console.log(result);
      if (!result.status) {
        toast.error(result?.Message);
        return;
      }

      result.status && dispatch(setToggleModal(false));
      toast.success(result?.message ?? "One time password sent!");
    } catch (error) {
      toast.error(JSON.stringify(error?.data?.message));
    }
  };
  const PinClearReset = () => {
    setOTP("");
    setPins("");
    setStep(1);
  };

  const methods = useFormContext();
  let renderSignUp = (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={cn(
        "bg-white w-[100%] h-[100%] mr-auto ml-auto p-8 polygon rounded-lg"
      )}
    >
      <div className="border-b-2 border-[#000]">
        <label className="text-gray-600">Phone Number</label>
        <PhoneInput
          defaultCountry="NG"
          international
          rules={{ required: true }}
          withCountryCallingCode
          countryCallingCodeEditable={false}
          ref={register}
          {...register("phoneNumber".replace(/\s/g, ""))}
          numberInputProps={{
            className: " py-4 b focus:ring-0 outline-0 focus:border-0",
          }}
          className={cn("w-full !border-0 pl-2 rounded-lg")}
          onChange={(phone) => {
            methods?.register("phoneNumber"?.replace(/\s/g, ""))?.onChange({
              target: { name: "phoneNumber", value: phone },
            });
          }}
        />
      </div>

      <Input
        name="email"
        label="Email"
        {...register("email", { required: true })}
        error={errors?.email?.message}
      />

      <Button
        isLoading={isLoading}
        className="text-white bg-primary p-5 w-[100%] mt-20"
        title="submit"
      >
        Verify registration
      </Button>
    </form>
  );

  let renderAuthCode = (
    <form
      onSubmit={onAuthSubmit}
      className={cn(
        "bg-white w-[100%] h-[100%] mr-auto ml-auto p-8 polygon rounded-lg"
      )}
    >
      <Typography className="text-[24px] leading-[24px] text-center mb-2 text-black">
        Registration Verification
      </Typography>
      <Typography className="text-[14px] leading-[24px] text-center text-black">
        Kindly enter the OTP code sent to{" "}
        <span className="text-primary">
          {phoneWithoutCountryCode(getValues("phoneNumber"))?.phoneNumber}
        </span>{" "}
        and <span className="text-primary">{getValues("email")}</span>
      </Typography>
      <OtpInput
        onChange={(otp) => setOTP(otp)}
        numInputs={6}
        value={OTP}
        isInputNum
        containerStyle="justify-center mt-20"
        inputStyle="!w-2/3 border-b-2 pb-2 border-black outline-none !text-black"
        separator={<span>{"  "}</span>}
      />

      <Button
        isLoading={isVerifying}
        className="text-white bg-primary p-5 w-[100%] mt-20"
        title="submit"
      >
        Verify OTP Code
      </Button>
      <Typography className="text-center mt-3">
        Resend code in
        {showResend ? (
          <span className="text-primary cursor-pointer">Resend</span>
        ) : (
          <Timer />
        )}
      </Typography>
    </form>
  );

  let renderCreatePin = (
    <form
      onSubmit={handleCreatePin}
      className={cn(
        "bg-white w-[100%] h-[100%] mr-auto ml-auto p-8 polygon rounded-lg"
      )}
    >
      <Typography className="text-[24px] leading-[24px] text-center mb-2">
        Create Pin
      </Typography>
      <Typography className="text-[14px] leading-[24px] text-center text-[#671E78] mb-16">
        Enter your Six digits account pin
      </Typography>

      <label className="text-black/30 mb-4 block text-sm">Password</label>
      <OtpInput
        isInputNum
        numInputs={6}
        value={pins.pin}
        onChange={(value) => setPins({ pin: value })}
        containerStyle="justify-center"
        inputStyle="!w-2/3 border-b-2 pb-3 border-black outline-none !text-black"
        separator={<span>{"  "}</span>}
      />

      <label className="text-black/30 mb-4 mt-7 block text-sm">
        Confirm Password
      </label>
      <OtpInput
        numInputs={6}
        isInputNum
        value={pins.confirmPin}
        onChange={(value) =>
          setPins((prev) => ({ ...prev, confirmPin: value }))
        }
        containerStyle="justify-center"
        inputStyle="!w-2/3 border-b-2 pb-3 border-black outline-none !text-black"
        separator={<span>{"  "}</span>}
      />

      <Button
        isLoading={isCreatingPin}
        className="text-white bg-primary p-5 w-[100%] mt-20"
        title="submit"
      >
        Create Pin
      </Button>

      <div className="flex items-center gap-5">
        <h4 className="text-red-600">Having problem with your account?</h4>
        <h4 onClick={PinClearReset} className="cursor-pointer font-bold">
          Reset
        </h4>
      </div>
    </form>
  );

  /* Setting the selected school to the first school in the array. */
  useEffect(() => {
    if (schools.length !== 0) {
      handleSchoolSelection(schools?.[0]);
    }
  }, [schools?.length, setSelectedSchools]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [buttonBgColor, setButtonBgColor] = useState("rgba(144, 36, 34, 0.9)");
  const handleButtonClick = (componentBgColor) => {
    setButtonBgColor(componentBgColor);
  };
  return (
    <div>
      <div className="bg-white rounded-lg ml-auto mr-auto p-10 w-[100%]">
        <div className="w-[100] ml-auto mr-auto ">
          {schools.length !== 0 ? (
            <Slider
              className="slide p-2 flex gap-5 justify-between"
              {...settings}
            >
              {schools?.map((school) => {
                const bg_index = school.colorValue;
                return (
                  <>
                    <div
                      key={school}
                      className="md:mx-3 card-image-bg rounded-lg"
                    >
                      <div
                        className={cn("rounded-lg")}
                        style={{ backgroundColor: bg_index }}
                      >
                        <div
                          onClick={() => {
                            handleSchoolSelection(school);
                            handleButtonClick(bg_index);
                          }}
                          key={school?.schoolid}
                          className={cn(`rounded-lg items-center py-3`)}
                        >
                          <div className="h-32 w-32 mx-auto py-5">
                            <img
                              src={
                                school?.logo ??
                                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJhVKpIXOG55THsJxJgug4CN50Jm3AgS3Zkg&usqp=CAU"
                              }
                              className="rounded-lg w-full h-full object-contain"
                              alt={school?.schoolname}
                            />
                          </div>

                          <p className="text-center font-extrabold text-sm mt- text-white">
                            {school?.schoolAbrv}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </Slider>
          ) : (
            <MoonLoader color="#671E78" className="mx-auto" />
          )}
        </div>
      </div>

      <div className="card-image-bg flex justify-between w-[100%] p-5 md:p-10 ml-auto mr-auto rounded-xl mt-3 relative z-0">
        <div className="flex flex-col md:flex-row ">
          <div
            style={{ backgroundColor: buttonBgColor }}
            className={cn(
              "rounded-xl left-0 bottom-0 absolute h-full w-full -z-10"
            )}
          />
          <div>
            <h4 className="text-white">{selectedSchool?.schoolname}</h4>
            <div className="flex items-center gap-2">
              <MapPinIcon className="h-5 w-5 text-white" />
              <Typography {...props} className="text-white">
                {selectedSchool?.schooladdress}
              </Typography>
            </div>
          </div>
        </div>
        <div className="w-20 h-20">
          <img
            src={
              selectedSchool?.logo ??
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJhVKpIXOG55THsJxJgug4CN50Jm3AgS3Zkg&usqp=CAU"
            }
            className="w-full h-full rounded-full"
            alt={selectedSchool?.schoolname}
          />
        </div>
      </div>

      <Modal ref={divRef} isOpen={isOpenModal}>
        <div className="p-5 md:p-10 bg-primary min-w-[300px] w-[30rem] mt-8 rounded-lg relative">
          <XMarkIcon
            onClick={() => dispatch(setToggleModal(false))}
            className="absolute right-2 top-1 h-8 w-8 text-white cursor-pointer"
          />
          <div className="flex flex-col place-items-center mb-[3rem]">
            <div>
              <Typography className="text-white">Sign up Today</Typography>
            </div>
            <div>
              <Typography className="text-xl text-white font-bold">
                Start Your Child's Registration
              </Typography>
            </div>
          </div>
          {step === 1
            ? renderSignUp
            : step === 2
            ? renderAuthCode
            : renderCreatePin}
        </div>
      </Modal>
      {/* <DevTool control={control} /> */}
    </div>
  );
};
export default SliderComponent;
