import React, { useEffect, useState } from "react";
import { Typography } from "../Typography";
import { BsGlobe2, BsFillChatDotsFill } from "react-icons/bs";
import { HiEnvelope } from "react-icons/hi2";
import { TiSocialLinkedin } from "react-icons/ti";
import { BsFillTelephoneFill } from "react-icons/bs";
import ClipLoader from "react-spinners/ClipLoader";
import {
  FaFacebookMessenger,
  FaTwitter,
  FaFacebookSquare,
} from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from "react-scroll";
// import { clickOnScroll } from "./slice";
import { useDispatch } from "react-redux";
// import { useGetSchoolsQuery } from "../../app/api";
import { useGetLasmabInfoQuery, useGetSchoolContactQuery } from "../../app/api";
import { useGetSchoolsQuery } from "../../app/api";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  height: "600px",
  overflow: "auto",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};
const Footer = ({
  props,
  handleSchoolSelection,
  handleButtonClick,
  clickOnScroll,
}) => {
  const { schools } = useGetSchoolsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      schools: data?.data ?? [],
    }),
  });
  const { data } = useGetLasmabInfoQuery();

  const lasmabData = data?.data?.lasmabInfo;
  const terms = data?.data?.terms;
  const policy = data?.data?.policy;
  const [openTerms, setTermsOpen] = React.useState(false);
  const [openPolicy, setPolicyOpen] = useState(false);
  const handleTermsOpen = () => setTermsOpen(true);
  const handleTermsClose = () => setTermsOpen(false);
  const handlePolicyClose = () => setPolicyOpen(false);
  const handlePolicyOpen = () => setPolicyOpen(true);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const interval = setInterval(() => {
      const newYear = new Date().getFullYear();
      if (newYear !== currentYear) {
        setCurrentYear(newYear);
      }
    }, 1000);

    return () => {
      clearInterval(interval); // Cleanup the interval when the component unmounts
    };
  }, [currentYear]);
  const dispatch = useDispatch();
  const { data: contacts } = useGetSchoolContactQuery();
  console.log("these are footer schools: ", schools);
  return (
    <>
      <div className="md:p-10 p-5 lg:p-5 bg-white">
        <div className="grid lg:mt-[90px] lg:flex lg:flex-row justify-around">
          <div className="grid justify-center items-center lg:place-items-start lg:flex lg:flex-col">
            <div className="md:flex justify-center md:justify-between items-center flex-row gap-5 mb-5 md:mb-0">
              <div className="flex items-center justify-center mb-5 md:mb-0">
                {lasmabData?.logoPath ? (
                  <img
                    className="md:w-[60px] md:h-[60px] mx-auto md:mx-0 sm:w-[40px] sm:h-[40px] h-[4rem] w-[4rem] rounded-[50%]"
                    src={lasmabData?.logoPath}
                    alt="this is the logo of lasmab on the footer "
                  />
                ) : (
                  <ClipLoader
                    color="#671E78"
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )}
              </div>
              <div className="lg:max-w-[300px]">
                <Typography
                  {...props}
                  className="text-xl lg:text-start text-center font-extrabold text-[#181433] "
                >
                  {lasmabData?.name}
                </Typography>
              </div>
            </div>
            <div className="flex justify-center items-center lg:justify-start flex-row gap-5 mb-5 md:mb-0">
              <a href="">
                <TiSocialLinkedin size={30} className="cursor-pointer" />
              </a>
              <a href={lasmabData?.facebookUrl}>
                <FaFacebookSquare size={20} className="cursor-pointer" />
              </a>
              <a href={lasmabData?.twitterUrl}>
                <FaTwitter size={20} className="cursor-pointer" />
              </a>
              <a href={lasmabData?.igUrl}>
                <AiFillInstagram size={30} className="cursor-pointer" />
              </a>
            </div>
          </div>
          <div className="lg:flex gap-x-10">
            <div className="text-center lg:text-left mb-5 lg:mb-0">
              <Typography
                {...props}
                className="text-xl font-extrabold text-[#181433]"
              >
                Quick Links
              </Typography>
              <ul className="mt-3 text-[#181433] ">
                <li>
                  <Link
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    className="cursor-pointer"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="vision"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    className="cursor-pointer"
                  >
                    Our Vision
                  </Link>
                </li>
                <li>
                  <Link
                    to="enrollment"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    className="cursor-pointer"
                  >
                    Our Schools
                  </Link>
                </li>
                <li>
                  <Link
                    to="enrollment"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    className="cursor-pointer"
                  >
                    Board
                  </Link>
                </li>
                <li>
                  <Link
                    to="news"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    className="cursor-pointer"
                  >
                    News
                  </Link>
                </li>
              </ul>
            </div>
            <div className="text-center lg:text-left mb-5 lg:mb-0">
              <Typography
                {...props}
                className="text-xl font-extrabold text-[#181433] mb-5"
              >
                Our Schools
              </Typography>
              {schools?.map((school, index) => (
                <ul className="text-[#181433]" key={school}>
                  <li>
                    <Link
                      className="cursor-pointer"
                      to="QuickView"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={0}
                      onClick={() => {
                        dispatch(clickOnScroll(index));
                        handleSchoolSelection(school);
                        handleButtonClick(school);
                      }}
                    >
                      {school.schoolAbrv}
                    </Link>
                  </li>
                </ul>
              ))}
            </div>
            <div className="text-center lg:text-left mb-5 lg:mb-0">
              <Typography
                {...props}
                className="text-xl font-extrabold text-[#181433]"
              >
                Contact Us
              </Typography>
              <ul className="mt-5 w-fit mx-auto">
                <li className="flex flex-row gap-3 cursor-pointer">
                  <BsGlobe2 />
                  <a href="https://www.lasmab.school">www.lasmab.school</a>
                </li>
                <li className="flex flex-row gap-3 cursor-pointer">
                  <HiEnvelope />
                  <a href={`mailto:${data?.data?.lasmabInfo?.email}`}>
                    {data?.data?.lasmabInfo?.email}
                  </a>
                </li>
                <li className="flex flex-row gap-3 cursor-pointer">
                  <BsFillTelephoneFill />
                  <a href={`tel:${data?.data?.lasmabInfo?.phoneNumber}`}>
                    {data?.data?.lasmabInfo?.phoneNumber}
                  </a>
                </li>
                <li className="flex flex-row gap-3 cursor-pointer">
                  <BsFillChatDotsFill />
                  <a href={`https://wa.me/${data?.data?.lasmabInfo?.helpline}`}>
                    Chat with an Admin
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center lg:mt-[100px] mt-[3rem] text-center lg:text-left">
          <p className="text-center cursor-pointer">
            {currentYear} LASMAB. All rights reserved.
            <br className="grid md:hidden " />
            <button className="underline mx-2" onClick={handlePolicyOpen}>
              Privacy Policy
            </button>
            <br className="grid md:hidden" />
            <button className="underline" onClick={handleTermsOpen}>
              Terms of Services
            </button>
          </p>
        </div>
        <Modal
          open={openTerms}
          onClose={handleTermsClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="w-[80vw] md:w-[600px]" sx={style}>
            <Box align="right">
              <IconButton onClick={handleTermsClose}>
                <HighlightOffIcon />
              </IconButton>
            </Box>
            <Typography
              align="center"
              className="text-lg text-primary underline mb-2"
            >
              Terms of Services
            </Typography>
            <Typography>{terms?.termContent}</Typography>
          </Box>
        </Modal>
        <Modal
          open={openPolicy}
          onClose={handlePolicyClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="w-[80vw] md:w-[600px]" sx={style}>
            <Box align="right">
              <IconButton onClick={handlePolicyClose}>
                <HighlightOffIcon />
              </IconButton>
            </Box>
            <Typography
              align="center"
              className="text-lg text-primary underline mb-2"
            >
              Privacy Policy
            </Typography>
            <Typography>{policy?.policyContent}</Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Footer;
